import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from "../components/ProcessrLogo"


const Header = ({ siteTitle }) => (

<nav className="navbar navbar-dark navbar-expand-lg bg-processr fixed-top">
  

  <Link to="/" style={{ color: `white`, textDecoration: `none` }}>
    <Logo /><span className="site-title ml-1">Processr.<span style={{ color: '#17a2b8' }}>IO</span></span>
  </Link>

<div className="navbar-nav navbar-collapse collapse">
  <ul className="navbar-nav ml-auto">
      <li className="nav-item"><Link to="/#water"  className="nav-link">Water &amp; Sewerage</Link></li>
      <li className="nav-item"><Link to="/#agents"  className="nav-link">AI Agents</Link></li>
      <li className="nav-item"><Link to="/#partners"   className="nav-link">Partners</Link></li>
      <li className="nav-item"><Link to="/#about"  className="nav-link">About Us</Link></li>
      <li className="nav-item"><Link to="/#contactus"  className="nav-link">Contact Us</Link></li>
    </ul>
    </div>

</nav>

)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
