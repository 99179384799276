/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"

import Header from "./header"
import "./layout.css"
import ColdtapLogo from "./ColdtapLogo"
import ProcessrIOTitle from './title'

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}


const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />

      <main>{children}
      
        <footer style={{ backgroundColor: '#eee'}}>

          <div className="container">
            <div className="row">
              <div className="col-md-6 mb-3">
                <div className="media">
                  <ColdtapLogo className="mr-3" />
                  <div className="media-body">
                    <a href="https://coldtap.io">Coldtap Australia Pty Ltd</a>
                    <p>© 2019-{new Date().getFullYear()}<br />
                    <small><ProcessrIOTitle nocolor /> is a registered trademark of Coldtap Australia Pty Ltd</small></p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div><Link to="/terms-and-conditions">Terms &amp; Conditions</Link></div>
                <div><Link to="/privacy-policy">Privacy Policy</Link></div>
                <div><Link to="/environmental-policy">Environmental Policy</Link></div>
              </div>
            </div>
        </div>


        </footer>

      </main>


    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
